* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

*::-webkit-scrollbar {
  display: none;
}

.App,
.am-modal-body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.viewPort,
.viewPortCart {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 45px;
  overflow-y: scroll;
  height: calc(100% - 30px); /*decreased 52 to 30 for spot space*/
  width: 100%;
}

.viewPortCart {
  position: absolute;
  top: 0px;
  height: calc(100% - 50px);
}

.checkoutBtn {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.checkoutBtn .am-button {
  margin: 0 auto;
}

.menuContainer {
  max-height: 100%;
  overflow-y: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.sideMenuContainer {
  -webkit-overflow-scrolling: touch;
  height: auto;
  overflow-y: scroll;
  float: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  
}

.sideMenuContainer .am-list-content {
  font-size: 13px !important;
}

.sideMenu {
  width: 100%;
  height: 100%;
}

.mainMenuContainer {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  -webkit-flex: 4 1;
          flex: 4 1;
}

.mainMenu {
  height: auto;
}

.menuHeader {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 6px;
}

ul,
li {
  list-style: none;
}

/* Item Info */
.itemInfoBox img {
  /*width: 110px !important;*/
  width:100%;
  max-width:512px;
  height: auto !important;
  padding: 6px !important;
  vertical-align: top !important;
}

.itemInfoBox .am-list-content {
  word-break: break-word !important;
  text-overflow: unset !important;
  white-space: unset !important;
}

.itemAddon .am-list-content,
.itemAddon .am-list-brief {
  font-size: 13px !important;
}

.itemAddon .radio {
  padding: 2.5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 5px;
}
.gray {
  color: gray;
}

.gridContainer {
  height: 100%;
}

.gridContainer:before {
  content: '';
  display: table;
}

.gridContainer:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 100px) {
  .gridItem {
    width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
  }
  .gridItem:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .gridItem:last-child {
    margin-right: 0;
  }
  .gridItem:nth-child(2n) {
    margin-right: 0;
    float: right;
  }
  .gridItem:nth-child(2n + 1) {
    clear: both;
  }
}

@media (min-width: 500px) {
  .gridItem {
    width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
  }
  .gridItem:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .gridItem:last-child {
    margin-right: 0;
  }
  .gridItem:nth-child(3n) {
    margin-right: 0;
    float: right;
  }
  .gridItem:nth-child(3n + 1) {
    clear: both;
  }
}

@media (min-width: 700px) {
  .gridItem {
    width: calc(99.9% * 1/4 - (30px - 30px * 1/4));
  }
  .gridItem:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .gridItem:last-child {
    margin-right: 0;
  }
  .gridItem:nth-child(4n) {
    margin-right: 0;
    float: right;
  }
  .gridItem:nth-child(4n + 1) {
    clear: both;
  }
}

@media (min-width: 900px) {
  .gridItem {
    width: calc(99.9% * 1/5 - (30px - 30px * 1/5));
  }
  .gridItem:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .gridItem:last-child {
    margin-right: 0;
  }
  .gridItem:nth-child(5n) {
    margin-right: 0;
    float: right;
  }
  .gridItem:nth-child(5n + 1) {
    clear: both;
  }
}

.gridItem {
  margin-top: 10px;
  display: table-cell;
  font-weight: normal;
  text-align: center;
  word-break: break-word;
  word-wrap: anywhere;
  background-color: #fff;
  font-size: 0.9em;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  position: relative;
}

.gridItemBox {
  margin-top: 5px;
  padding: 9px;
}

.gridItemBox .gridItem:not(:first-child) {
}

.gridItem img {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.orderHeader {
  background-color: #333;
  color: #fff;
  padding: 3px;
  padding-left: 12px;
}

.li-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 3 1;
          flex: 3 1;
  vertical-align: middle;
}
.li-head {
  margin: 0;
}
.li-sub {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0;
}

.max-lines,
.sideMenu .am-list-content {
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
}

.sideMenuContainer .am-list-content {
  text-overflow: clip !important;
}

.navBar .am-navbar-title {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
  text-align: center;
  white-space: normal !important;
  -webkit-flex: 2.5 1;
          flex: 2.5 1;
}

.shortTitle .am-navbar-title {
  font-size: 16px;
  font-weight: bold;
  -webkit-flex: 2.5 1;
          flex: 2.5 1;
}

.listEntryImage {
  display: block;
  width: 100%;
  height: auto;
}

.addonGroup {
  counter-reset: line-number;
}

.addonIndexNumber {
  counter-increment: line-number;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 2px;
  padding-top: 12px;
  padding-left: 12px;
  font-weight: bold;
  color: black;
}

.addonIndexNumber:before {
  content: counter(line-number) ". ";
}

.am-stepper-input {
  pointer-events: none;
  cursor: default;
}

.specialRequestInput {
  margin-bottom: 9px;
}

.specialRequestInput .am-list-line {
  border-bottom: 1px solid #ddd !important;
}

.specialRequestInput .am-list-item .am-input-label,
.addonList .am-input-label {
  width: 30px !important;
  margin-top: 5px !important;
}

.addonList {
  margin-bottom: 10px !important;
}

@media (min-width: 500px) {
  .listEntryImage {
    display: block;
    width: 110px;
    height: auto;
	min-height:auto;
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.paymentHeader {
  font-weight: bold;
  color: white;
}

.popup-list .am-list-header {
  background-color: #1e90ff;
}

.thumbnailPlaceholder {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: white;
  /*background-image: url("/static/placeholder.jpg");*/
  background-size: contain;
}

.thumbnailPlaceholderLoading {
  position: absolute;
  top: 50%;
  -webkit-transform: translateXY(-50%, -50%);
          transform: translateXY(-50%, -50%);
  left: 50%;
}

.imageLoaded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/* BODY */
body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
}
html,body {
    height:100%;
}
/* GENERAL STEPPER */
/* STEPPER BOX */
.am-stepper .am-stepper-handler-wrap .am-stepper-handler {
  background-color: white;
}

/* STEPPER TEXT */
.am-stepper .am-stepper-input-wrap .am-stepper-input {
  color: black;
  font-size: 16px;
}

.am-stepper-handler-active {
  background-color: #ddd !important;
}

/* END OF GENERAL STEPPER */

/* END OF BODY */

/* HEADER */
/* BACKGROUND */
.header {
  background-color: white;
}

/* LOGO */
.header img {
  height: 32px;
}

/* TEXT */
.header .am-navbar-title {
  color: black;
}

/* SEARCH ICON */
.header .am-icon-search {
  color: #108ee9;
}

/* SETTINGS ICON */
.header .am-icon-ellipsis {
  color: #108ee9;
}

/* SEARCHBAR PLACEHOLDER TEXT */
.searchbar .am-search-synthetic-ph-placeholder {
  color: #bbb;
  font-size: 15px;
}

/* SEARCHBAR INPUT */
.searchbar .am-search-value[type="search"] {
  color: #bbb;
  font-size: 15px;
}

.searchbar .am-search-cancel {
  font-size: 16px;
  color: #108ee9;
}

/* END OF HEADER */

/* MENU */
/* BACKGROUND */
.menu_background {
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50%;
}

/* MENU CATEGORY HEADER */
#mainMenu .category_header {
  font-weight: 600;
  color: black;
  font-size: 14px;
}

/* MENU ITEM NAME */
#mainMenu .item_header {
  color: black;
  font-size: 14px;
  max-lines: 2;
}

/* MENU ITEM PRICE */
#mainMenu .item_price {
  color: black;
  font-size: 14px;
}

/* GRID VIEW - MENU ITEM */
.grid_item {
  background-color: #ddd;
}

/* GRID VIEW - TICK */
.grid_addToCart {
  color: #3b9949;
  right: 5px;
  font-size: 22px;
}

/* MENU STEPPER BACKGROUND */
.menu_stepper .am-stepper-handler-wrap .am-stepper-handler {
}

/* MENU STEPPER TEXT */
.menu_stepper .am-stepper-input-wrap .am-stepper-input {
}

/* END OF MENU */

/* SIDEBAR */
/* BACKGROUND COLOR */
.sidebar .am-list-body {
  background-color: transparent;
}

.sidebar .am-list-item {
  background-color: transparent;
}

/* SIDEBAR ITEM */
.sideMenuItem {
  font-size: 12px;
  color: black;
}

/* END OF SIDEBAR */

/* ITEM INFO */
/* ITEM INFO HEADER*/
.info_header {
  background-color: black;
  color: white;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 9px;
  padding-left: 12px;
  text-align: left;
}

/* ITEM INFO CLOSE BUTTON*/
.info_close {
  color: white;
  font-size: 15px;
  position: absolute;
  right: 12px;
  top: 9px;
}

/* ITEM INFO DESCRIPTION HEADER*/
.desc_header {
  font-size: 15px;
  color: black;
}

/* ITEM INFO DESCRIPTION TEXT*/
.desc_text {
  font-size: 15px;
  color: #888;
}

/* ITEM INFO BOTTOM BOX */
.am-modal-body .bottom {
  background-color: white;
  border-top: 1px solid rgb(238, 238, 238);
}

/* ITEM INFO ADD TO CART STEPPER BOX */
.info_stepper .am-stepper-handler {
}

/* ITEM INFO ADD TO CART STEPPER TEXT */
.info_stepper .am-stepper-input-wrap .am-stepper-input {
}

.info_addToCart {
  background-color: black;
  color: white;
  font-size: 15px;
}

/* END OF ITEM INFO */

/* TABLE POPUP */
/* TABLE POPUP HEADER*/
.table_popup_header {
  font-size: 18px;
  color: black;
  font-weight: 600;
}

/* CALL WAITER BUTTON */
.table_popup .am-modal-button-group-v .am-modal-button:first-child {
  color: #108ee9;
  font-size: 18px;
}

/* CALL WAITER BUTTON */
.table_popup .am-modal-button-group-v .am-modal-button:nth-child(2) {
  color: #108ee9;
  font-size: 18px;
}

/* CALL WAITER BUTTON */
.table_popup .am-modal-button-group-v .am-modal-button:nth-child(3) {
  color: #108ee9;
  font-size: 18px;
}

/* CALL WAITER BUTTON */
.table_popup .am-modal-button-group-v .am-modal-button:last-child {  
  font-size: 18px;
}

/* END OF TABLE POPUP */

/* ADDON */
/* ADDON HEADER */
.addon_header {
  font-weight: bold;
  font-size: 0.9em;
  color: black;
  background-color: rgb(193, 193, 193);
}

/* CART PAGE - ADDON DESCRIPTION */
.cart .addon_desc {
  font-size: 1em;
  color: black;
}

/* ITEM INFO - ADDON DESCRIPTION */
.am-modal-body .addon_desc {
  font-size: 15px;
  color: #888;
}

/* ITEM INFO - ADDON STEPPER BUTTON*/
.am-modal-body .am-stepper-handler {
  background-color: white;
}

/* ITEM INFO - ADDON STEPPER TEXT*/
.am-modal-body .addon_stepper .am-stepper-input {
  font-size: 16px;
  color: #000;
}

/* CART PAGE - ADDON STEPPER BUTTON*/
.cart .addon_stepper .am-stepper-handler {
}

/* CART PAGE - ADDON STEPPER TEXT*/
.cart .addon_stepper .am-stepper-input {
}

/* END OF ADDON */

/* CART */

/* CART BACKGROUND */
.cart {
  background-color: #f5f5f9;
}

.cart_itemName {
  font-size: 0.9em;
  color: black;
  font-weight: 600;
}

.cart_itemPrice {
  font-size: 14px;
  color: black;
}

.cart_removeItem {
  font-size: 14px;
  color: black;
}

.cart_stepper .am-stepper-handler-wrap .am-stepper-handler {
}

.cart_stepper .am-stepper-input-wrap .am-stepper-input {
}

/* CHECKOUT BUTTON */
.checkout_button {
  color: black;
  background-color: #fff;
  font-size: 18px;
}

/* END OF CART */

/* ORDERS */
/* ORDERS BACKGROUND */
.orders {
  background-color: #f5f5f9;
}

/* ORDERS LIST ITEM BACKGROUND */
.orders .am-list-item {
  background-color: white;
}

/* ORDER NO */
.orders .order_no {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

/* ORDER PAYMENT STATUS */
.orders .order_payment_status {
  color: #888;
  font-size: 15px;
  font-weight: normal;
}

/* ORDER TIMESTAMP */
.orders .order_timestamp {
  color: #888;
  font-size: 15px;
}

/* ORDER VIEW QR */
.orders .am-list-extra {
  color: #888 !important;
  font-size: 16px !important;
}

/* END OF ORDERS */

/* FOOD STEPPER */

.food-stepper-container {
  display: -webkit-flex;
  display: flex;
  max-width: 50vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px;
  width: 138px;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.food-stepper-container.flexible {
  width: unset !important;
}

.food-stepper-select {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  color: #000;
  position: absolute;
  box-sizing: content-box;
  width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
}

.food-stepper-select-disabled {
  opacity: 0.3;
  background-color: white !important;
  cursor: unset !important;
}

.food-stepper-select-disabled:active {
  background-color: white !important;
}

.food-stepper-select-disabled:focus {
  background-color: white !important;
}

.food-stepper-select:focus {
  background-color: #ddd;
}

.food-stepper-select:active {
  background-color: #ddd;
}

.food-stepper-select svg {
  width: 15px;
  height: 15px;
}

.food-stepper-select.plus {
  cursor: pointer;
  right: 0;
}

.food-stepper-select.minus {
  cursor: pointer;
  left: 0;
}

.food-stepper-number {
  width: 60px;
  font-size: 16px;
  color: #000;
  text-align: center;
  border: 0;
  padding: 0;
  background: none;
  vertical-align: middle;
  line-height: 1.15;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-family: sans-serif;
}

/* END OF FOOD STEPPER */

/* ORDER POPUP */

.order_popup .am-modal-content {
  background-color: white;
}

.order_popup_header {
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.order_popup_no {
  font-size: 15px;
  color: #888;
}

.order_popup .am-modal-button-group-v .am-modal-button {
  color: #108ee9;
  font-size: 18px;
}

/* END OF ORDER POPUP */

/* FOOTER */
/* FOOTER BACKGROUND */
.am-tab-bar-bar {
  background-color: white !important;
}

/* END OF FOOTER */

/* ACTIVE COLORS */

.active {
  /*background-color: #007bff !important;
  color: white !important;*/
  background-color: royalblue/darkblue;
    color: #fff!important;
    border-bottom: 5px solid !important;
    border-bottom-color: lightgray !important;
}

/* END OF ACTIVE */

/* MEDIA - STYLING BASED ON SCREEN SIZE */
@media only screen and (min-width: 600px) {
  .sideMenuItem {
    font-size: 1em !important;
  }
  .socialLogo {		
	width:30%;
    }
  
}
@media only screen and (max-width: 600px) {
   .socialLogo {		
	width:90%;
     }
}

.socialModal .am-modal-body {
}

.socialBox {
  margin: 0 auto;
}
.socialHeader {
  color: #000;
}
.socialLogoOld {
  width: 100%;
  padding: 5%;
  max-width:512px;
}
.foodLogo {
  width: 25%;
  padding: 5%;
  max-width:512px;  
  
}
.socialLogo {		
	bottom:0;
	padding-top:160px;
	text-align:center;
}

.socialButton {
  padding: 9px;
} 
.fullscreen-enabled .menuContainer, .fullscreen-enabled .viewPort {
 background:#f5f5f9;
 
}
.table_popup
{
	z-index:auto !important;
}
:-webkit-full-screen .viewPort {
  background:#f5f5f9;
}
:-ms-fullscreen .viewPort {
  background:#f5f5f9;
}
:fullscreen .viewPort {
  background:#f5f5f9;
}

.table_question .ant-radio-inner
{
	display:none !important;	
}
.top-margin
{
	margin-bottom:10px;
	z-index:auto;
}

.withoutLog{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}
.withoutLog .logoWrap{
    text-align: center;
}
.withoutLog .logoWrap img{
    width: 100%;
    max-width: 240px;
}
.withoutLog .btnSet{
    text-align: center;
}
.withoutLog .bypassbtn{
    padding: 0 50px;
    font-size: 16px;
    background: #FFF;
    border: 1px solid #ccc !important;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
	color: #108ee9;	
}

.withoutLog .btnSet .socialButton .social-icon{
	width:30px !important;
	height:30px !important;
	margin-right: 10px;
}
.withoutLog .btnSet .socialButton{
	padding: 6px 15px;
    font-size: 16px;
    background: #FFF;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
	color: #108ee9;
	vertical-align:middle;
}
.withoutLog .btnSet .socialButton:last-child,
.withoutLog .btnSet .bypassbtn:last-child{
	margin-right:0;
}
.withoutLog .poweredByLog{
    text-align: center;
	margin-top:30px;
	margin-bottom:70px;
}
.withoutLog .poweredByLog img{
    width:100%;
    max-width:120px;
}
.withoutLog{
	padding:0 15px;
}

@media only screen and (max-width:800px){
	.withoutLog .btnSet .socialButton{
		margin-right:0;
		margin-bottom:12px;
		display:block;
		text-align:left;
	}
	.withoutLog{
		text-align:left;
	}
}

.hideDiv
{
	display:none;
}

.custom-btn {
    color: #fff;
    background-color: #108ee9;
    border: 1PX solid #108ee9;
    border-radius: 5px;
    width: 125px;
    float: left;
    margin-left: 10px;
}
.table_question .qa-heading {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
display: block;
margin-top:0px;
/*border-bottom: 1PX solid #ddd;*/
}

.table_question .option-list
{
position: relative;
/*border-top: 1PX solid #ddd;*/
box-sizing: border-box;
text-align: center;
text-decoration: none;
outline: none;
font-size: 18px;
/*height: 50px;*/
line-height: 40px;
display: block;
width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
color: #108ee9;
}

.table_question .ant-radio-group {
    display: block;
}
	
.ant-radio-wrapper .ant-radio
{
	padding-right:5px;
}
.pull-left
{
	text-align:left;
}

.error
{
	color:red;
}
.border-botom
{
	border-bottom: gray 1px solid;
}
.loginText
{
	margin-top:20px;
	margin-bottom:20px;
	font-size:80%;
}
.class-btn{	
    -webkit-touch-callout: none;
    -webkit-flex: 1;
    flex: 1 1;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #108ee9;
    font-size: 18px;
    height: 35px;
    line-height: 50px;
    display: block;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.clearCartBtn
{
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}
.trashIcon
{
	margin-right:20px;
}
.table_question .am-modal-content
{
	padding-top:0px !important;
}
.table_question .am-modal-body {    
        padding: 0px !important;
}
.table_question .am-modal-body .am-list-item {    
        padding-left: 0px !important;
}

.descripttionStyle
{
	text-align:left;
	padding:5px;
	padding-left:10px !important;
}

.table_question .am-list-content
{
	padding-left:20px;
}

.table_question .am-modal-title {
   
    padding: 10px;
}

.table_question  .btnClose
{
border-top: 1PX solid #ddd;
-webkit-flex: 1 1;
        flex: 1 1;
box-sizing: border-box;
text-align: center;
text-decoration: none;
outline: none;
color: #108ee9;
font-size: 18px;
line-height: 50px;
display: block;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
.table_question .am-list-item .am-list-line .am-list-content
{
	color:unset;
}
.themeBtn {
    display: block;
    padding: 4px;    
    color: #7f7f7f;
    font-size: 12px;
    margin: 0 auto;
}
		
.optselection{
	padding: 6px 10px;
    font-size: 16px;
    background: #FFF;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
	color: #108ee9;
	text-align:center;
    margin-top:10px;
    max-width:90%;
}
.am-modal-button-group-h .am-modal-button:first-child {
     color: #108ee9 
}
.selectedOpt
{
	background: #108ee9;
	color: #FFF;
}
.btnCloseTopmargin
{
	margin-top:10px;
}
.rowTopmargin
{
	margin-top:10px;
}

.am-navbar-title {
	text-align:left !important;
}
.am-navbar-title > span{
	float:right;
	margin-top:11px;
}

@media only screen and (max-width:767px) {
	.sideMenuContainer
	{
		min-width: 35%;
	}	
}
@media only screen and (max-width:480px) {
    .gridItemButton
    {
    position: absolute !important;
    bottom: 0px;
    width: 100%;
    }
    .mobilepadding
    {
        padding-bottom:35px !important;
    }
	.am-navbar-title > span {
	font-size:12px;
	}
   .itemInfoBox img {
        width: 100% !important;
       max-width: 512px;
	      
   }
	
}
.mainmenu .am-navbar-left {
	max-width:15% !important;
	-webkit-flex: 0 0 45px;
	        flex: 0 0 45px;
}
.mainmenu .am-navbar-left  i{
	margin:0 !important;
}
.mainmenu .am-navbar-title{
    text-align: center !important;
}
.mainmenu .am-navbar-title span{ 
    margin-top: 0px !important;
    display: inline-block !important;
	float: none;    
	 /*white-space: pre-wrap;*/
    overflow: hidden;
    /*text-overflow: ellipsis;*/
	overflow: hidden;
    line-height: 18px;
    max-height: 37px;
	    -webkit-flex-grow: 0;
	            flex-grow: 0;
	    -webkit-flex-shrink: 0;
	            flex-shrink: 0;
	    -webkit-flex-basis: calc(100% - 80px);
	            flex-basis: calc(100% - 80px);
}
.sideMenuContainer
{
	max-width:250px;
}
.cart_item img {  
    max-width: 110px;
}
.takeAway span
{
	margin-left:15px;
}
.otherSection
{
	width:100%
}
.takeAway .am-input-label-5 {
     width: 25px !important; 
    }
.navBar .am-navbar-title	{
	margin-left:auto !important;
}
.popDiv .payOpt ul li img {
    max-width: 100%;
}

.am-navbar-light{
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.checkoutBtn .am-button{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    width: 80%;
    padding: 0;
    
    -webkit-justify-content: center;
    
            justify-content: center;
}
.checkoutBtn.checkoutBtnGrey .am-button {
    background-color: #D3D3D3;
	color: #000;
}
.checkoutBtn .am-button.confirmBtn span:first-child{
    text-align: center;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    line-height: 40px;
}
.checkoutBtn .am-button .priceBtn{
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    text-align: center;
    background: #fff;
    line-height: 40px;
    color: #000;
}

.mainmenu .am-navbar-title{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 130px);
            flex-basis: calc(100% - 130px);
	 white-space: unset;
}
.mainmenu .am-navbar-right {
    -webkit-flex: 0 0 45px;
            flex: 0 0 45px;
    margin: 0;
    padding-right: 10px;
}
.mainmenu .am-navbar-left {
    -webkit-flex: 0 0 45px;
            flex: 0 0 45px;
    text-align: center;
}
.mainmenu .am-navbar-title img{
    height: 40px;
    /*flex: 0 0 80px;*/
}
.mainmenu .am-navbar-title img{
	-webkit-flex: 0 0 80px;
	        flex: 0 0 80px;
}
.mainmenu .am-navbar-title span{
	font-size:11px;
}
.mainmenu .am-navbar-title span.shortTitleMenu{
	    font-size: 16px;
}
.mainmenu .am-navbar-title span.noimage{
	-webkit-flex:0 0 100%;
	        flex:0 0 100%;
}
	
@media only screen and (max-width:400px){
.mainmenu .am-navbar-title span{
    margin-top: 0px !important;
    line-height: 15px;	
    width: 60%;
    max-height: 28px;
    overflow: hidden;
	/*padding-bottom: 31px;*/
}
}

@media only screen and (min-width:400px){
	.mainmenu .am-navbar-title img{ 
	float:left !important;
	
	}
}
.borderTop
{
	border-top: 1px solid #ddd;
}

.payments .am-list-line,
.payments .am-list-line .am-list-content {
    padding: 0;
}
.payments .am-list-line .am-list-content .checkout > div{
    border-bottom: 1px solid #ddd;
}
.payments .am-list-line .am-list-content .checkout > div:last-child{
border-color:transparent;
}
.payments .am-list-line .am-list-content .checkout{
	margin-bottom:15px;
}
.payments .themeBtn{
	background: #fff;
    /*color: #fff;*/
    text-align: center;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
@media(min-width:550px)
{
	.mainInfodiv
	{
		display:-webkit-flex;
		display:flex;
	}
	.lt-section
	{
		-webkit-flex:0 0 35%;
		        flex:0 0 35%;
	}
	.rt-section
	{
		-webkit-flex:0 0 65%;
		        flex:0 0 65%;
	}
}

.repeat-btn
{
width: 4%;
float: left;
margin-top: 15px;
margin-left: 10px;
}
.orderDiv
{
	 width: 95%;
	 float:left;
}
@media only screena and (max-width:767px){
	.shortTitle .am-navbar-title{
		font-size: 13px;
	}
	
}

@media only screen and (min-width:768px){
.mainmenu .am-navbar-title span{
    font-size: 13px;
}
}

@media only screen and (max-width:767px){
.mainmenu .am-navbar-title span.shortTitleMenu {
    font-size: 13px;
   }
   .orderDiv
	{
		width:80%;
		float:left;
	}
}
.mainmenu .am-navbar-title span.menuTitle {
    font-size: 13px;
   }

.popDiv .payOpt ul li{
    vertical-align: top;
}
.popDiv .payOpt ul li.paypalULLI{
	 width: 100%;
    height: 100%;
}

.gridItem:nth-child(5n){
float:left
}
.am-accordion .am-accordion-item .am-accordion-header{
	font-size:13px;
	text-transform:capitalize;
	font-weight:bold;
	padding-left:10px;
	cursor:pointer;
}
.itemNotAvail{
	font-size:12px;
	text-align:left;
}
.am-accordion .am-accordion-item .am-accordion-header i{
	display:none;
}
.am-accordion .am-accordion-item .am-accordion-header[aria-expanded~="true"] i{
	/*transform: rotate(90deg);*/
	height: 12px;
    color:#007bff;
	display:block;
}
.am-accordion .am-accordion-item .am-accordion-header i{
	background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQzQzM3NjVGMkVDNDExRUE5N0QzQzBEMkI3NDZENUM0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQzQzM3NjYwMkVDNDExRUE5N0QzQzBEMkI3NDZENUM0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDNDMzc2NUQyRUM0MTFFQTk3RDNDMEQyQjc0NkQ1QzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDNDMzc2NUUyRUM0MTFFQTk3RDNDMEQyQjc0NkQ1QzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7EavrrAAABs0lEQVR42qyWTShEURTH571sZkd2JN8LC4QkbBQl9qQkYjY2ZMEsbSyQLGSlRhRhKVNKs1GDlGHHDkVEYcxGNvifOq9e13kfM++e+nXnnnfv+79zz7mnMULzF6Eg9htt+uczFlIRDCMgCWbzQpoNAssYpnjaDkpNzQI9NgHLukyNAiUYtoVHDzoj2QcFgn/G1BTFHIYG4dEKCiNhBK0u2ADYEfyHEOilH0EjKQPrgv8TjFmToCKU6LDgH0IUzzpENkCb4J+GwIHdYdpGqgy/l3MQDAv+IwgsqU56eRG4BO8gBSo9BGo5CtXuQZ+0gURWQT3P68AxKHQR2XOImPKQcRJpUXzF4AzkC+tjoEbwRyGQdPoqEokL/mo+urCSh1FhbRwCi27nSyLj/ELVKvjoyKrAlrDmDvR7VQmd7Q+35HNbbixrBqcuVUd5+PIjQvYNOrjKypU1rQ57J8GJn3q3X8Y0aAQ3PvZtUvPze2vVG5/miF5c9jyCiWxag9RWXrldvAnPKH/dIBNUhOyWL+aH4qdKvM62ybk1yCfQyV9PtgvWcumkXl34CtCfgwSI5Nqu/wQYAE0DXMsedecRAAAAAElFTkSuQmCC);
	-webkit-transform: rotate(0deg) !important;
	        transform: rotate(0deg) !important;
}

.customList{
	-webkit-flex: 0 0 110px;
	        flex: 0 0 110px;
}
@media (min-width:481px){
	.smallIconArrow
	{
		display:none;
	}  
    .cocoVerticalMenu
    {
        display: none;
    }   
    .cocoHorizontalMenu
    {
        display: block;
    } 
    .ant-menu-submenu-popup {       
        display: none;
    }
}
@media (max-width:480px){

	.gridContainer, .listCocoContainer{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.gridContainer .gridItem{
	-webkit-flex-grow: 0;
	        flex-grow: 0;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-flex-basis: calc(50% - 5px);
	        flex-basis: calc(50% - 5px);
	margin-right: 0 !important;
	}
	.gridContainer:before,
	.gridContainer:after{
		display:none;
	}
	.menuContainer .sideMenuContainer{
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
		transition:all 0.5s ease;
		position: fixed;
		left: 0;		
		z-index: 9;
		overflow: visible;
		height: calc(100vh - 90px);
		background: rgba(255, 255, 255, 0.60);
		max-width: 35%;
		min-width: 170px;
	}
	.menuContainer.popupMenu .sideMenuContainer
	{
		background: rgba(0, 0, 0, 0.8);
	}
	.sideMenuContainer >.sideMenu > .am-list-body{
		overflow:auto;
		height:100%;
		padding-bottom: 60px;
	}
	.sideMenuContainer >.sideMenu > .am-list-body:after,
	.sideMenuContainer >.sideMenu > .am-list-body:before{
		background:transparent;
	}
	.menuContainer .sideMenuContainer .am-accordion {
		padding-bottom: 70px;
		height: calc(100vh - 95px);
		overflow:auto;		
		background: rgba(0, 0, 0, 0.8);
	}
	.menuContainer.fixedMenuSection .sideMenuContainer .am-accordion {
		background: #fff;
	}
	.am-tabs-tab-bar-wrap
	{
		z-index: 99;
	}
	.menuContainer.toggleMenuCls .sideMenuContainer{
		-webkit-transform: translate(-170px, 0px);
		        transform: translate(-170px, 0px);
	}
	.menuContainer .mainMenuContainer{
		transition:all 0.5s ease;		
	}

	.smallIconArrow{
		position: absolute;
		top: 50%;
		z-index: 9;
		font-size: 20px;
		text-align: center;
		line-height: 30px;
		left: 75%;
		right: auto;		
		-webkit-transform: translate(0%, -50%);		
		        transform: translate(0%, -50%);
		width: 40px;
		height: 60px;
		/*border: 1px solid #aeaeae;*/
		background-color: #aaa5a575;
		display:block;
	}
	.smallIconArrow svg{
		position: absolute;
		top: 50%;
		left: 50%;
		height:30px;
		-webkit-transform: translate(-50%, -50%) rotate(180deg);
		        transform: translate(-50%, -50%) rotate(180deg);
	}
	.toggleMenuCls .smallIconArrow svg{
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		        transform: translate(-50%, -50%) rotate(0deg);
		
	}
	.toggleMenuCls .smallIconArrow{		
		left: 100%;
	}
	.sideMenuContainer .am-accordion-item,
	.sideMenuContainer .am-list-body,
	.sideMenuContainer .am-list-body .am-list-item,
	.am-accordion .am-accordion-item .am-accordion-header,
	.am-accordion .am-accordion-item .am-accordion-content{
		background-color: rgba(255, 255, 255, 0);
		color:#fff;
		cursor:pointer;
	}
	.sideMenuContainer .am-list-content{
		color:#fff !important;
	}
	.menuContainer.fixedMenuSection .sideMenuContainer .am-list-content,
	.menuContainer.fixedMenuSection .sideMenuContainer .am-accordion-header{
		color:#333 !important;
		cursor:pointer;
	}
	.sideMenuContainer .am-list-body .am-list-item .am-list-line{
		border-bottom: 1px solid #ddd;
	}
	
	.fixedMenuSection.menuContainer .sideMenuContainer{
		position: static;
		-webkit-transform: none;
		        transform: none;
		-webkit-flex: 0 0 130px;
		        flex: 0 0 130px;
		min-width:unset;
		max-width:35%;
		overflow:auto;
	}
	.fixedMenuSection.menuContainer .smallIconArrow{
		display:none;
	}
	
	.fixedMenuImage .customList{
		-webkit-flex: 0 0 43px;
		        flex: 0 0 43px;
	}
	.fbAppInCls .sideMenuContainer {
		height: 80%;
	}
}


.hideItemSection
{
	display:none !important;
}
.hideErrorMessage
{
	display:none !important;
}
.notavialableCls{
    position: absolute;
    bottom: 49px;
    z-index: 5;
    margin: 0 auto;
    left: 0;
    right: 0;
	background:#f5f5f9;
	width:100%;
}
.notavialableCls > div{
    background: red;
    width: 80%;
    padding: 10px;
    color: #fff;
    border: 2px solid #f2f2f2;
	padding-left: 30px;
	    margin: 0 auto;
    margin-bottom: 7px;
}
.my-accordion .am-list-item .am-list-line-wrap .am-list-content, .am-list-item .am-list-line-wrap .am-list-extra {
    white-space: normal;
    padding-left: 10px;
}
.checkoutBtn {
    z-index: 9;
}

.hideshowArrow
{
	display:none;
}
.rewardWrap * {
        box-sizing: border-box;
    }
    .rewardWrap{
        padding:30px 15px 60px;
		position:static;
    }
    .rewBtnSet .buttonrw{
        display: -webkit-flex;
        display: flex;
        width: 100%;
        max-width: 375px;
        border:1px solid #ccc;
        text-decoration: none;
        color: #fff;
        margin: 0 auto;
        margin-bottom: 15px;
    }
    .rewBtnSet .buttonrw .btnLog{
        -webkit-flex: 0 0 80px;
                flex: 0 0 80px;
        text-align: center;
        display: -webkit-flex;
        display: flex;
        margin: 0 auto;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 5px;
    }
    .rewBtnSet .buttonrw .btnLog svg{
        width: 20px;
		border-radius: 50%;
		height: 40px;
    }
    .rewBtnSet .buttonrw .rewText{
        -webkit-flex-grow: 0;
                flex-grow: 0;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        -webkit-flex-basis: calc(100% - 80px);
                flex-basis: calc(100% - 80px);
        padding: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        font-size: 18px;
		    line-height: normal;
    }
    .rewBtnSet .buttonrw.facebook .rewText{
        background: #3b5998;
    }
    .rewBtnSet .buttonrw.googlePls .rewText{
        background: #dd4b39;
    }
    .rewardWrap .logorew{
        text-align: center;
        margin-bottom: 30px;
		padding-top: 15px;
    }
    
    .rewardWrap .hdpoints{
        text-align: center;
    }
    .rewBtnSet .buttonrw.waiter .rewText{
        color: #0b8ce9;
    }
    .rewardWrap .hdpoints{
        margin-bottom: 15px;
    }
    .rewardWrap .hdpoints h4{
        font-size: 25px;
    }
    .rewardWrap .rwcheck > label{
        font-size: 21px;
        padding: 10px 10px 10px 30px;
        border: 1px dashed #d9d9d9;
        display: block;
        position: relative;
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
    }
    .rewardWrap .rwcheck label input{
        position: absolute;
        left: 5px;
        top: calc(50% - 7px);
    }
    .rewardWrap .rwcheck {
        padding-bottom:15px;
        border-bottom: 2px solid #777; 
		
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
    }
    .rewardWrap .skiplogin{
		text-align:right;
		width: 100%;
		max-width: 375px;
		/*border: 1px solid #ccc;*/
		text-decoration: none;
		margin: 0 auto 15px;
		padding: 10px;
    }
    .rewardWrap .skiplogin a{
        font-size: 18px;
        color: #0b8ce9;
        text-decoration: none;
        text-transform: capitalize;
        display: inline-block;
        padding: 0;
        border: 0;
		line-height: normal;
		    height: auto;
    }
    .rewardWrap .skiplogin a::before{
		display:none;
	}
    .marbot15{
        margin-bottom: 15px !important;
    }
	
    .rewardWrap .rewBtnSet  .socialButton{
        display: -webkit-flex;
        display: flex;
        width: 100%;
        max-width: 375px;
        border: 1px solid #ccc;
        text-decoration: none;
        color: #fff;
        margin: 0 auto;
        margin-bottom: 15px;
        padding: 0;
    }
    .rewardWrap .rewBtnSet  .socialButton  a{
        -webkit-flex: 0 0 80px;
                flex: 0 0 80px;
        text-align: center;
        display: -webkit-flex;
        display: flex;
        margin: 0 auto;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 5px;
    }
    .rewardWrap .rewBtnSet  .socialButton  a div,
    .rewardWrap .rewBtnSet  .socialButton  a svg{
        position: static !important;
    }
    .rewardWrap .rewBtnSet  .socialButton  a svg{
		width: 40px !important;
		height: 40px !important;
		border-radius: 50% !important	;
	}
    .rewardWrap .rewBtnSet > .socialButton > span{
        -webkit-flex-grow: 0;
                flex-grow: 0;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        -webkit-flex-basis: calc(100% - 80px);
                flex-basis: calc(100% - 80px);
        padding: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        font-size: 18px;
    }
    .rewardWrap .rewBtnSet > .socialButton.facebook > span{
        background: #3b5998;
    }
    .rewardWrap .rewBtnSet > .socialButton.google > span{
        background: #dd4b39;
    }
    .rewardWrap > .am-button.bypassbtn{
        width: 100%;
        max-width: 375px;
		margin:0 auto;
	}
    .rewardWrap .rwcheck > label > .am-list-item {
        position: static !important;
    }
    .rewardWrap .rwcheck > label > .am-list-item .am-list-thumb{
        position: absolute;
        left: 10px;
        margin-right: 0 !important;
    }
    .rewardWrap .rwcheck > label > .am-list-item .am-list-line .am-list-content{
        text-overflow: unset !important;
        white-space: unset !important;
    }
    .rewardWrap .btnSet.callwaiter > .am-button {
		height: auto;
		line-height: normal;
		border: 0;
		overflow: visible;
	}
	.rewardWrap .btnSet.callwaiter>.am-button:before{
		display:none;
	}
	.rewardWrap .rewBtnSet > .socialButton > span.iconz{
		 -webkit-flex: 0 0 80px;
		         flex: 0 0 80px;
	}
	.rewardWrap .rewBtnSet > .socialButton > span.txt{
		background: #dfa800;
	}
	.rewardWrap .rewBtnSet > .socialButton > span.iconz > div{
		    width: 40px;
			height: 40px;
			background: #dfa800;
			text-align: center;
			padding: 5px;
			border-radius: 50%;
			    margin: 0 auto;
	}
	.rewardWrap .rewBtnSet > .socialButton > span.iconz > div img{
		width:30px;
	}
	.rewardWrap .rewBtnSet > .socialButton.customBtn{
		border-radius:0;
		height: 52px;
	}
	.am-modal-body .btnSetP  > .am-button,
	.am-modal-body .btnSetP  > .am-button:before{
		    border-radius: 0;
			border-top:0;
	}
	.am-modal-body .btnSetP  > .am-button:last-child{
		border-left:0;
	}
	.am-modal-body .btnSetP{
		display:-webkit-flex;
		display:flex;
		-webkit-align-items:unset;
		        align-items:unset;
	}
	.pineappleModal .am-list-item {
		border-bottom:1px solid #eee;
	}
	body .oldmodal .customBtn{
		padding:0 !important;
		display: -webkit-flex !important;
		display: flex !important;
		-webkit-align-items:center !important;
		        align-items:center !important;
		border-radius:0;
	}
	body .oldmodal .customBtn .iconz{
		    margin-right: 10px !important;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			        align-items: center;
			-webkit-flex: 0 0 47px;
			        flex: 0 0 47px;
			-webkit-justify-content: flex-end;
			        justify-content: flex-end;
	}
	body .oldmodal .customBtn .iconz .ccle{
		    display: -webkit-flex;
		    display: flex;
			-webkit-align-items: center;
			        align-items: center;
			    width: 30px;
			height: 30px;
			border-radius: 50%;
			
	}
	body .oldmodal .customBtn .iconz .ccle img{
			width:30px !important;
			margin: 0 auto;
			color: #fff !important;
	}
	
	body .oldmodal .customBtn .txt{
		color:#108ee9 !important;
	}
	body .withoutLog .btnSet .am-button{
		height: 47px;
	}
	body .am-button.customBtn:before{
		border:none;
	}
	body .oldmodal .am-modal-content .customWap {
		padding: 0;
		min-width: 240px;
	}
	body .oldmodal .am-modal-content .customWap .customBtn{
		height: 42px !important;
		font-size: 16px;
		width: 100%;
		border: 0;
	}
	
	
    @media only screen and (max-width:768px){
        .rewardWrap .rewBtnSet > .socialButton > span{
            font-size: 16px;
        }
			.rewardWrap .hdpoints h4 {
			font-size: 20px;
			}
			.am-modal-body .btnSetP{
				/*border-top:1px solid #eee;*/
			}
			
    }
.pineappleModal .heading {    
    height: 50px;   
    display: -webkit-flex;   
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
	 color: #000;
}
.pineappleModal .heading img{
	width:30px;
	margin-right: 10px;
}
.pineappleModal .heading span{
		font-size: 1.17em;
    font-weight: bold;
}



    .homeWrap h4{
      font-size: 18px;
      color: #272727;
      margin-bottom:10px;
    }
    .homeWrap .logohome img{
      max-width: 179px;
    }
    .homeWrap .logohome{
       margin-bottom: 15px;
    }
    .homeWrap .opratTime {
       font-size: 16px;
       
    }
    .homeWrap ul {
       list-style: none;
       padding:0;
       margin: 0;
       display: -webkit-flex;
       display: flex;
       -webkit-justify-content: space-around;
               justify-content: space-around;
       -webkit-flex-wrap: wrap;
               flex-wrap: wrap;
       margin-top: 40px;
    }
    .homeWrap ul li{
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      margin-bottom: 20px;
    }
    .homeWrap ul li .inneList{
         background-color: #ebebeb;
         padding: 20px;
         text-align: left;
         max-width: 250px;
         width: 90%;
         margin: 0 auto;
         box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
         border-radius: 6px;
			 
		   padding: 0;
		   position: relative;
		   overflow: hidden;
    }
    .homeWrap ul li .inneList .heading{
       font-size: 10px;
       color: #272727;
    	max-height: 35px;
	
    }
	
    .homeWrap ul li .inneList img {
        		 
         width: 100%;
         height: 150px;
         object-fit: cover;
		 display: block;
    }
    .homeWrap ul li .inneList .sponso .spofoot{
      	  
      background: #fff;
      text-align: center;
      padding: 10px;
	  color: #000;
    }
	
    .homeWrap ul li .inneList .sponso .spofoot.notAvai{
		opacity: 0.5;
	}
	
	.homeWrap ul li .inneList .imgStr.notAvai img{
		opacity: 0.3;
	}
    .homeWrap ul li .inneList .sponso .spofoot span{
      overflow: hidden;
      display: block;
      height: 40px;

    }
	.homePowerBy {
		/*background: #f1f1f1;*/
		max-width:250px;
		margin: 0 auto;
		padding: 15px;
		
	}
	
	.homeWrap ul li .inneList .imgStr {
       position: relative;
    }
    .homeWrap ul li .inneList .imgStr .heading {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: #fff;
      max-width: 200px;
      margin-bottom: 0;
      padding: 2px 5px;
      border-radius: 3px;
      max-height: 38px;
      overflow: hidden;
    }
@media only screen and (max-width:480px) {
	.homeWrap ul li{
      -webkit-flex: 0 0 50%;
              flex: 0 0 50%;
	  max-width: 50%;
    }
	
}

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}
.sideMenuContainer .am-list-body .am-list-item {
     padding-left:0;
}
.sideMenuContainer .am-list-body .am-list-item .am-list-line{
     padding-left:15px;
}
.extraEmpty .am-list-line,.test-extra .am-accordion-header {
    border-bottom: none !important;
}
.menuContainer{
    height: calc(100vh - 95px);
}
.menu-item {
  padding: 12px 10px 4px 10px;
  margin: 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border: none;
}

 
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.scroll-width {
  width: 100rem;
}

.mainMenuContainer{
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-visibility: hidden;
	scrollbar-width: none;
	
}
/*.menuContainer {
    height: calc(100vh - 145px);
    height: -webkit-calc(100vh - 145px);
    height: -moz-calc(100vh - 145px);
	
}*/
.mainMenuContainer::-webkit-scrollbar { width: 0 !important }


.appMain {
	position: fixed;
	height: 100%;
	width: 100%; 
	top: 0px;
	left:0px;
}


.horizontal-menu {
		position:static;
		    margin-top: 45px;
	}
	.am-tabs-horizontal .am-tabs-content-wrap .am-tabs-pane-wrap .am-tab-bar-item > .menuview2
	{
		top:0;
		height:100%;
	}
	.appMain.menuAppMain {
		position: fixed;		
		width: 100%; 
		top: 0px;
		left:0px;
	}
	
	
	.mainMenuContainer .gridItemBox > .menuHeader{
		/*padding-top:15px;*/
		padding-top:13px
	}
	.horizontal-menu.scrollMore .menu-wrapper--inner{
		-webkit-transform:none !important;
		        transform:none !important;
	}
	
	
	
@media only screen and ( min-width: 767px ){

	.horizontal-menu {
		height: 0;
		opacity: 0;
	}
	
	.appMain.menuAppMain.loadedClass {		
		height: calc(100% - 47px);
		height: -moz-calc(100% - 47px);
	}
	.appMain.menuAppMain {
		height: calc(100% - 0px);
	}
	
}
@media only screen and ( max-width: 767px ){
.menuAppMain .sideMenuContainer{
		display:none !important;
}

.appMain.menuAppMain.loadedClass {		
		height: calc(100% - 90px);
		height: -moz-calc(100% - 90px);
	}
}


.menu-wrapper--inner{
    background-color: #0088cc;
	/*transform: translate3d(10px, 0px, 0px);*/
}

.menu-item-wrapper {
    display: inline-block;
  /*  max-width: 150px;*/
    max-height: 60px;
    white-space: normal;
    text-align: center；
}


/* my css */
.menu-wrapper--inner {
	background-color:#fff;
}
.am-navbar-light {
	border-bottom:1px solid #ddd;
}
.menu-item-wrapper {
	color: #888888;
}
.menu-wrapper--inner .menu-item-wrapper.active{
	color:#108ee9 !important;
	border-color:#108ee9 !important;
}
.requiredText
{
	width:40%;
	float:right;
	font-size: 13px !important;
	text-align:center;
	font-weight:normal;
}
.bluetext
{
	color:#108ee9 !important;
}
.spanItemInfo {
	display:inline-block;
	padding-left:20px;
	width:60%;
}
.addonIndexNumber::before{
	position:absolute;
	/*top:0;
	left:0;*/
}

.greytext,.am-modal-button-group-h .am-modal-button:last-child , .table_popup .am-modal-button-group-v .am-modal-button:last-child 
{
	color:#808080 !important;
}
.headtext
{
	color:#000;
}
.paymentwrapper{
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 20px;
	max-height:calc(100vh - 180px);
	overflow:auto;
}
.paymentwrapper .paymentRow:last-child{
	border-color: transition;
}
.paymentReceipt
{
	margin-top:0px;
	width:290px;
}
.customPrompt .am-modal-button:first-child {
    color:#808080 !important;
}
.customPrompt .am-modal-button:last-child {
    color:#108ee9 !important;
}

.cart_item  .am-list-line .am-list-brief {
   margin-top: 0px !important;
}

.cart_item .am-list-line
{
	padding-right:5px;
}
.cart_item .am-list-line {
    border-bottom: none !important;
}
.cartHeader {   
    height: 1px !important;
}

		.loginSeparator {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            text-align: center;
            width: 50%;
            padding-top: 10px;
        }

        .loginSeparator::before, .loginSeparator::after {
            content: '';
            -webkit-flex: 1 1;
                    flex: 1 1;
            border-bottom: 1px solid #000;
        }

        .loginSeparator::before {
            margin-right: .5em;
        }

        .loginSeparator::after {
            margin-left: .5em;
        }
		.loginheader {
            width: 100%;
            min-height: 25vh;
            color: #000;
            background: linear-gradient(#fff 65%, #efefef );
            position: relative;
        }
		.div-header {
            padding-bottom: 20px;
        }
		.button-style1 {
            background-color: #000;
            text-transform: uppercase;
            color: #fff;
            width: 12%;
            font-weight: 600;
            font-size:10px;
            line-height:15px;
            position:absolute;
            top:10%;
            right:10%;
        }
		.newModal .logorew img{
        max-width: 150px;
    }
	.newModal h1 {
            font-size: 20px;
            font-weight: 800;
        }
		.newModal .rewardWrap .am-list-item {
    margin-bottom: 10px;
}
.newModal .rewardWrap .am-list-item .am-input-control input{
    height: 35px;
    padding: 5px 10px;
    background: #ebebeb;
    border-radius: 20px;
    color: #8c8c8c;
}
.newModal .rewardWrap .am-list-item .am-input-control input::-webkit-input-placeholder{
    color: #8c8c8c;
}
.newModal .rewardWrap .am-list-item .am-input-control input:-ms-input-placeholder{
    color: #8c8c8c;
}
.newModal .rewardWrap .am-list-item .am-input-control input::placeholder{
    color: #8c8c8c;
}
.newModal .formSection .am-button{
    display: inline-block;
    min-width: 130px;
    background: #000;
    border-radius: 15px;
    line-height: 35px;
    color: #fff;
    font-size: 13px;
    border: 0;
}
.newModal .rewardWrap .loginSeparator{
    width: 100%;
    margin-bottom: 20px;
}
.newModal .rewardWrap .rewBtnSet{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.newModal .rewardWrap .rewBtnSet > span{
    padding: 0 7px;
}
.newModal .rewardWrap .rewBtnSet > span > span{
    font-size: 10px;
    display: block;
	margin-top:5px;
}
.newModal .rewardWrap .rewBtnSet > span > a {
    display: block;
}
.newModal .rewardWrap .rwcheck {
    margin-top: 15px;
    border-color: transparent;
}
.newModal .rewardWrap .rwcheck label .am-list-item{
    margin-bottom: 0;
}
.newModal .rewardWrap .rwcheck label .am-list-content {
    font-size: 13px;
}
.newModal .rewardWrap .skiplogin > a{
    font-size: 14px;
    color: #b1b1b1;
}
.newModal .iconsloginpage {
    list-style: none;
    padding:0;
    padding-bottom: 10px;
}
.newModal .iconsloginpage li{
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #000;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 32px;
    text-align: center;
}
.newModal .iconsloginpage li:last-child{
    margin-right: 0;
}
.newModal .rewardWrap .rewBtnSet>span.callwaiter .am-button {
border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid #ccc;
margin: 0 auto;
background-color: #ffde00;
}
.newModal .div-header {
    padding: 10px;
   /* text-align: right;*/
}
.newModal .div-header .am-button { 
    right: 0;
    height: auto;
    padding: 4px 10px;
    width: auto;
	 position: static;
    display: inline-block;
}
.newModal .rewardWrap {
    padding: 20px 15px 20px;
    position: static;
	 max-width: 560px;
	 margin: auto;
}

.cart_item .am-list-line-wrap .am-list-extra
{
	padding:0px;
}
 .stepperInfo {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
		 -webkit-justify-content: space-between;
		         justify-content: space-between;
    }
    .stepperInfo .removeBtninfo{
        font-size: 0 !important;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        color: #000;
        box-sizing: content-box;
        width: 30px;
        height: 30px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        background-color: #fff;
        margin-left: 5px;
    }
    .stepperInfo .removeBtninfo svg{
        font-size: 14px;
    }
.cart_item .qtytextfield input{
	text-align:right;
}

.cart_item .am-list-line {
	    display: -webkit-flex;
	    display: flex;
    -webkit-align-items: unset;
            align-items: unset;
}
.cart_item .am-list-line .am-list-content{
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: unset;
            align-items: unset;
    padding: 0;
}
.cart_item .am-list-line .am-list-content > div{
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 7px 0;
}
.cart_item .am-list-line .am-list-extra {
	    display: -webkit-flex;
	    display: flex;
}
.cart_item .am-list-line .am-list-extra .qtytextfield{
	    display: -webkit-flex;
	    display: flex;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.cart_item  {
	padding: 3px 15px;
}
.loadingDiv
{
	text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  
    width: 120px;
    height: 120px;
    background-color: white;
}
.loadingDiv img, .loadingIcon img{
	max-width: 60px;
    max-height: 60px;
}

.table_question .qa-heading {
	color: black !important;
    font-size: 16px;
    padding: 10px;
    height: auto;
	line-height: unset;
	 white-space: pre-line;
	
}

.table_question textarea,.table_question .am-input-control input, .table_question select,.location-search-input{   
    display: block;
    width: 100%;   
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /*color: #495057;*/
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.table_question .option-list {   
    min-height: 40px;
    height: auto;
}
.table_question .option-list
{ 
 line-height: normal;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;	
}
.table_question ::-webkit-input-placeholder,.table_question :-moz-placeholder,.table_question ::-moz-placeholder,.table_question ::-ms-input-placeholder {
   font-style: italic;
   white-space: pre-line;
}

.table_question .option-list.quesionfooter{
	width:50%;
	text-align:center;
	float: left;
}

.table_question .option-list.cancelBtn{
	color:unset;
}

.table_question .am-list-item:not(:last-child) .am-list-line {
     border-bottom: 0px;
}
.table_question .am-list-item .am-list-line {
	padding:unset;
}
.table_question .optionpadding
{
	padding-left:5px;
    padding-right:5px;
    text-align: center;
}



.table_question .option-list {
	padding: 0 15px;
}
.table_question .option-list  .locationselection{
	word-break: break-word;
    white-space: normal;
	    font-size: 15px;
    color: #000;
}
.max-lines, .sideMenu .am-list-content {
   max-height: unset;
}
@media only screen and (max-width:480px) {
 .listvw {
	 display: -webkit-flex;
	 display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
 }
 .listvw .infoItem{
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        display: -webkit-flex;
        display: flex;
		width: 100%;
    }
    .listvw .infoItem .imgdv{
        position: relative;
        width: 130px;
        -webkit-flex: 0 0 130px;
                flex: 0 0 130px;
    }
     .listvw .infoItem .imgdv .thumbnailPlaceholder{
        position: absolute;
        background: transparent;
    }
  .listvw .infoItem .imgdv .gridEntryImage {
        position: relative;
    }
    .listvw .infoItem .cont{
        padding: 6px;
        -webkit-flex-grow: 0;
                flex-grow: 0;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        -webkit-flex-basis: calc(100% - 130px);
                flex-basis: calc(100% - 130px);
        width: calc(100% - 130px);
    }
}

.imageQa
{
	width:85%;
}
.qaheight50
{
	height:auto;
	max-height: calc(100vh - 250px);
    overflow-y: scroll;
}
.qaheight90
{
	height:auto;
	max-height: calc(100vh - 100px);
    overflow-y: scroll;
}

.gridcirclebgwhte
{
	background-color:#fff;
}
.newGridtheme .gridItem img  {
    border-radius: 50%;
	width:80%;
	right:0;
	margin:auto;
}
 .itemPrice   {
	color:grey;
 }
.break-new-theme-btn {
    position: absolute !important;
    top: -20px;
    right: -10px;
    background: transparent;
    font-size: 0;
    border: 0;
    padding: 5px;
	width:50px;
}

.newGridtheme  .island-wide-delivery {
	background-color:transparent;
}
.break-new-theme-btn::before{
border-color:transparent !important;
}

.newGridtheme .thumbnailPlaceholder {
    padding-top: 90%;
}
.autocomplete-dropdown-container{
       box-shadow: 0 1px 10px 0 rgba(0,0,0,.05);
       overflow: auto;
    max-width: 90%;
    z-index: 99;
    margin: 0 auto 10px;
	

    }
    .autocomplete-dropdown-container > div
    {
        display: block;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #ccc;
        text-align: left;
        padding: 6px;
		z-index:9999;
    }
	.autocomplete-dropdown-container span {
        display: block;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;       
        text-align: left;
        padding: 6px;
    }
    .autocomplete-dropdown-container span:hover {
        background: #eee;
    }
	.div-header:first-child
	{
		text-align: left;
		padding:10px;
   }
	.div-header:last-child{
		float: right;		
		padding:10px;
	}
	.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
   }
  .am-accordion-header
    {
	  cursor:pointer;
  }

.itemCatList .thinnercls{
    padding:5px;
    border-bottom: 1px solid #d2d2d2;
}
.thinnercls
{
	padding-bottom:15px !important;
}
.defaultimgRight
{
	margin-left:110px;
}
@media only screen and (max-width:767px){
	.thinnercls
	{
	padding-bottom:5px !important;
	}
    .rightstepper .food-stepper-container {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        width: 100%;
        max-width: 100%;
        display: -webkit-flex;
        display: flex;
    }
    .rightstepper .food-stepper-container .food-stepper-select.minus{
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        left: auto;
        position: static;
    }
    .rightstepper .food-stepper-container .food-stepper-number{
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        position: static;
    }
    .rightstepper .food-stepper-container .food-stepper-select.plus{
        right: auto;
        position: static;
    } 
	.thinnercls .customList {
	-webkit-flex: 0 0 70px;
	flex: 0 0 70px;	
	}
	.rightstepper .food-stepper-container {
	   padding:0px;
	}
	
	
}
@media only screen and (max-width:480px) {
	.hideLightImage
	{
	display:none;
	}

    .mainMenuContainer{
        max-height: calc(100vh - 100px);
        padding-bottom: 40px;
    }
    .menuContainer .sideMenuContainer .am-accordion {
		height: calc(100vh - 130px);		
	}
}
.paddingleft15
{
padding-left:15px;
}
.paddingleft0
{
padding-left:0px;
}

.marginRight10
{
margin-right:10px;
}
@media only screen and (max-width: 767px)
{
	.fbAppInCls {
		height: calc(100% - 50px) !important;
		height: -moz-calc(100% - 50px) !important;
	}
	.appMain.menuAppMain.fbAppInCls {
		height: calc(100% - 140px) !important;
		height: -moz-calc(100% - 140px) !important;
	}
	
}
.blcktext
{
    color:#000 !important;
}
.fontboldstyle
{
    font-weight: bold;
}
.qtyc
{
    color:green;
    margin-right:2px;
    font-weight:bold;
}

.am-badge-text {
  
    background-color: green;
}

.spacebottom .am-modal-header {
    padding: 6px 10px 0px;
}
.spacebottom .am-modal-footer
{
    margin-top:10px;
}

.spacebottom .am-modal-button-group-h .am-modal-button:first-child {
	color:grey;
}

.spacebottom .am-modal-button-group-h .am-modal-button:last-child {
    color: #108ee9 !important;
}
.spacebottom .am-modal-title {   
    width: 95% !important;
    padding-left:0 px;
}
.spacebottom .am-modal-close
{
    top:15px;
    right:5px;
    cursor: pointer;
}
.checkstatusPopup
{
    max-height: 70vh;
    overflow: auto;
}
.headerflash
{
    z-index: 99999;
    width: 100%;    
    position: fixed;
    top:0px;
}
.headerflash marquee
{
    padding:5px;
}
.flashexist .mainmenu,.flashexist .searchbar
{
    top:25px !important;
}
.flashexist .menuContainer
{
    padding-top: 25px;
}
@media only screen and (max-width: 767px)
{
    .flashexist.menutype2 .horizontal-menu
    {
        margin-top:70px;
    }
    .flashexist.menutype2 .menuContainer
    {
        padding-top:unset;
    }
	.appMain.menuAppMain.loadedClass.flashexist.menutype2 {
        height: calc(100% - 120px);
        height: -moz-calc(100% - 120px);
    }
	.recomSection .loadingIcon img
    {
    max-width:60px;
    }
}
.table_question.postqa .am-modal-content{
    text-align: left;
   
}
.table_question.postqa .am-modal-body{
    max-width:95%;
    word-wrap: break-word;
	 padding-left: 15px !important;
}

.table_question .qa-heading
{
    text-align: center;
}
.table_question .qa-label {
    color: #000 !important;
    font-size: 12px;
    line-height: unset;
    white-space: pre-line;
    display: inline-block;
    margin-bottom: .5rem;
}
.table_question.pineappleModal .am-modal-content
{
padding:10px;
}
.paymentwrapper
{
    max-height: 60vh;
}
.paymentReceipt .heading{
    padding:10px;
}
.payments .am-modal-content .am-modal-body
{
    padding:2px;
    padding-top:0px;

}
.payments ul{
    max-height:60vh;
    overflow: auto;
}
.am-checkbox.am-checkbox-checked .am-checkbox-inner {
    border-color:green !important;
    background: green !important;
}
.menu-wrapper
{
    overflow: scroll !important;
}
.menu-wrapper--inner
{
    max-width:100%;
}
.am-modal-transparent
{
    width:90% !important;
    max-width:480px;
}

.table_question .am-modal-body{
    overflow: auto;
}
.errorqa
{
    color:red;
   /* font-size: 12px;
    text-align: right;*/
}
.bluemsgqa
{
    color:#108ee9 !important;
    font-size:20px;
}
.table_question .qa-label{
    margin-bottom: 0;
    margin-bottom: 5px;
    line-height: 1;
    display: block;
}
.qaheight90 > div:not(.qa-heading){
    margin-bottom: 15px;
    
}
.qaheight90 textarea,
.qaheight90 select{
    margin-top: 5px;
}
.qaheight90 .am-list-line::after{
    display:none !important;
}
.boxshp{
    border: 1px solid #ced4da;
    border-radius: 5px;
}
.fbAppInCls .emptydiv
{
    background: #fff;
    height:140px;
}
 .am-modal-button-group-h .am-modal-button:first-child {
    color: #808080 !important; 
}

 .am-modal-button-group-h .am-modal-button:last-child{   
    color: #108ee9 !important;
}
@-moz-document url-prefix() {
    .menu-item {
        padding: 2px 10px 2px 10px;
    }
    .table_question.postqa .am-modal-body {
        height: calc(100vh - 100px);
    }
	.table_question.postqa.payments .am-modal-body{
    height: calc(100vh - 243px);
    }
    .table_question.postqa .am-modal-body {
        max-width: 100%;
    }
  }
  .clearCartBtn .bypassbtn {
    width:300px;
    padding:0 10px !important;
 } 
 .checkstatusPopup {
    display: block !important;
}
.liInner
{
    display:-webkit-flex;
    display:flex;
    border-top:1px solid #ddd;
    padding: 10px 0px;
}
.liInner img, .liInner div img
{
    width:75px;
    vertical-align: middle;
    max-height: 60px;
}
.liInner.stripe div
{
    width:100%;
}
.liInner span ,.liInner div span
{
    width:80%;
    display: inline-block;
    padding-left:10px;
    margin: auto;
}
.imgsec {
	height: 100%;
}
.imgsec .qaheight90 {
    height: 100%;
}
.imgsec .qaheight90 > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0;
}
.imgsec .qa-heading {
    margin-bottom: auto;
}
.imgsec .option-list {
    margin-bottom: auto;
}
textarea::-webkit-input-placeholder {
  color: #bbb;
}

textarea:-ms-input-placeholder {
  color: #bbb;  
}

textarea::placeholder {
  color: #bbb;  
}
.calendersect svg
{
float: right;
position: relative;
top: -30px;
margin-right: 10px;	
}
.addongrpbg
{
    background-color: rgb(51, 51, 51);
}
.carthead{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: rgb(0, 0, 0);
    color: #fff;
}
.specialRheader
{
    background-color: black;
    color: white;
    padding: 4px;
    padding-left: 12px;
    text-align: left;
    margin-top: 10px;
}
.bottomBtn
{
    color: white;
    background-color: black;
}

.table_question.postqa.image .am-modal-body {
    height: unset;
}
.disable .am-list-item input,.disable textarea
{
	background-color:#F5F5F5
}
  .in.modal {
    display:-webkit-flex !important;
    display:flex !important;
  }
  
  .modal-dialog {
    margin: auto;
  }
  
.shareBtns
{
    position: relative;
    top: -67px;
    float: right;
    right: 20px;   
    padding: 10px 0px 0px 10px;
	display: -webkit-flex;
	display: flex;
	background-color: #fff;
}

.shareBtns button
{
	margin:auto;
	margin-right:10px;
}
.itemInfoBox img
{
	max-width:unset;
}
.calendersect .errorqa svg {
    float: unset;
    position: unset;
    top: unset;
    margin-right: unset;
}
.paypalbtn
{
    width:50%;
}
.shareBtnsloading
{
    position: relative;
    top: -100px;
    float: right;
}
.loading
 { 
  width:150px;
  text-align: center;
  }
  
.loading:after {
  content: ' .';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
  font: 300 4em/150% Impact;
  }
.viewPortCart {   
    scroll-behavior: auto;
}

@-webkit-keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
	  }
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);
	  }
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
	  }
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
	  }
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);
	  }
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
	  }
}
.dateSection
{
	text-align:center;
}

/* Chef Recommended */
.itemslist .chefRecommended{
    background-image: url(https://www.foodzaps.com/public/img/chef-recommended.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-transform: uppercase;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 2;
    width: 40px;
    height: 40px;
}
.itemslist.gridItem .chefRecommended {
    background-image: url(https://www.foodzaps.com/public/img/chef-recommended.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-transform: uppercase;
    position: absolute;
    top: -15px;
    left: -10px;
    z-index: 2;
    width: 50px;
    height: 50px;
}
.itemslist.thinnercls .chefRecommended {
    background-image: url(https://www.foodzaps.com/public/img/chef-recommended.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-transform: uppercase;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 2;
    width: 40px;
    height: 40px;
}
.ant-menu-submenu,.ant-menu-item
  {
    text-transform: capitalize;
  }

@media only screen and (max-width:480px) {
       
   .ant-menu-submenu-popup {       
        width: 100%;
        left: 0px !important;
        top: 90px !important;
        display: block;
    }
}

.unisoloSec .mainmenu
{
    height:110px;
}
.unisoloSec .mainmenu .am-navbar-title {
    max-height: 5.8em;
}
.unisoloSec .mainmenu img
{
    height:80px !important;
    padding-left:5px;
}
.unisoloSec .mainmenu i
{
    font-size: 40px !important;
}
.unisoloSec .mainmenu .am-navbar-title > span {
    max-width: 45% !important;
}
.unisoloSec .menuPort
{
    top:110px !important;
    height: calc(100% - 110px);
	height:-webkit-calc(100% - 110px);
}
.paymentwrapper.outLetCls table tr td:last-child {
    display: none;
}

.paymentwrapper.outLetCls table tr:nth-last-child(-n+7) {
    display: none;
}

.paymentwrapper.outLetCls table tbody tr:nth-last-child(-n+2){
  display: revert;
}
.paymentwrapper.outLetCls table tbody tr:nth-last-child(-n+2) td{
  display: revert;
}
.paymentwrapper.outLetCls table tbody tr:first-child,.paymentwrapper.outLetCls table tbody tr:first-child td{
  display: revert;
}
.paymentwrapper.outLetCls table tbody tr:nth-child(2),.paymentwrapper.outLetCls table tbody tr:nth-child(2) td{
  display: revert;
}

.cartDrawer .viewPortCart
{
	top:45px;
}
.ant-drawer-content-wrapper .ant-drawer-body{
    padding: 0;
}
.ant-drawer-content-wrapper .ant-drawer-body .drawermenu{
    display: -webkit-flex;
    display: flex;
    padding: 18px;
    border-bottom: 1px solid #ade461;
    overflow: hidden;
    font-size: 16px;
}
.ant-drawer-content-wrapper .ant-drawer-body .drawermenu > span{
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}
.ant-drawer-content-wrapper .ant-drawer-body .drawermenu > span svg{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.ant-drawer-content-wrapper .ant-drawer-body .drawermenu > span + span:before{
    content: "";
    left: 0;
    height: 400px;
    width: 0;
    position: absolute;
    top: -100px;
    border-left: 1px solid #ade461;
}
.cartDrawer .withoutLog .poweredByLog
{
	margin-top:0px;
}
.cartDrawer .checkoutBtn
{
	padding-right: 15px;
    padding-left: 15px;
}
.ant-drawer-content-wrapper .ant-drawer-body .viewPortCart{
    left: 0;
}
.ant-drawer-content-wrapper .ant-drawer-body .withoutLog .am-button{
    width: 100%;
}
/*
.menutype4 .am-modal-content .bottom > div {
    display: flex;
    gap: 10px;
}
.menutype4 .mainmenu .am-navbar-right .anticon.anticon-shopping-cart{
    position: relative;
}
.menutype4 .mainmenu .am-navbar-right .anticon.anticon-shopping-cart .cartCount{
    position: absolute;
    width: 15px;
    height: 15px;
    background: #000;
    font-size: 11px;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    color: #fff;
    right: 0;
    top: 10px;
}

.menutype4 .searchbar{
   height:80px;
}*/
.cartDrawer .ant-drawer-content-wrapper{
    width: 100% !important;
    max-width: 430px !important;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content{
    background: #f6f6f6 !important;
}

.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart{
    padding-right: 15px;
    padding-left: 15px;
    height: calc(100% - 140px);
    overflow-y: scroll;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cart-header-text{
    font-size: 24px !important;
    font-weight: 600;
    line-height: 1;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cart-header-text{
    font-size: 12px;
    color: #000;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartHeader{
    background: #ebebeb;
    margin-top: 15px;
    margin-bottom: 15px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup{
    background-color: #ade461;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
    color: #000;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode{
    padding-top: 15px;
    padding-bottom: 15px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode:first-child{
    padding-top: 0;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode:last-child{
    padding-bottom: 0 !important;
    border-color: transparent !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode + .ordermode{
    border-top: 1px solid #ebebeb;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode .flex-mode .fw600{
    font-weight: 600;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode .flex-mode div:not(.fw600){
    font-size: 12px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode{
    border-bottom: 1px solid #ebebeb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    border-top-color: transparent !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cartPickup .ordermode svg{
    width: 30px;
    height: 30px;
    right: 0 !important;
    position: static !important;
    -webkit-flex: 0 0 30px;
            flex: 0 0 30px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .checkoutBtn{
    position: static;
	margin-top: 10px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .checkoutBtn .poweredByLog{
    margin-top: 10px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .checkoutBtn .poweredByLog p{
    width: auto;
    display: block;
    margin-bottom: 10px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .checkoutBtn .poweredByLog a{
    display: inline-block;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-line .innerItemName p{
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 5px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-line .innerItemName .am-list-brief{
    font-size: 10px !important;
    color: #333;
}

.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item {
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-left: 0px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-thumb{
    width: 70px;
    -webkit-flex: 0 0 70px;
            flex: 0 0 70px;
    max-width: 70px;
    height: 70px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-thumb .marginRight10{
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
    cursor: pointer;
    z-index: 1;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-thumb img{
    width: 100% !important;
    height: 100% !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 0px;
    padding-bottom: 0px; 
    width: calc(100% - 70px);
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 70px);
            flex-basis: calc(100% - 70px);
    max-width: calc(100% - 70px);
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .cart-item-text{
    padding: 0;
    font-size: 12px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item:not(.addonSummary) .am-list-line > *{
    width: calc(100% - 20px);
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: calc(100% - 20px);
            flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line > .am-list-extra > div{
    width: 100%;
    display: -webkit-flex;
    display: flex;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line > .am-list-extra .food-stepper-select{
    border: 2px solid #ade461 !important;
    background-color: #fff !important;
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
    border-radius: 5px !important;
}

.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line > .am-list-extra .food-stepper-container > *{
    position: static;
    margin-right: 10px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line > .am-list-extra .food-stepper-container{
    padding: 0 !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line > .am-list-extra .food-stepper-container .food-stepper-number{
    border-radius: 5px !important;
    width: 50px;
    -webkit-flex: 0 0 50px;
            flex: 0 0 50px;
    position: static;
    border: 1px solid #ccc!important;
    height: 45px;
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body::after ,
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body::before,
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line::after{
    display: none !important;
} 
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item,
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body {
    background: transparent !important;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item .am-list-line .am-list-content .cartItemName{
    padding-top: 0 !important;
}
.cartDrawer .checkoutBtn .withoutLog .poweredByLog{
	margin-top:5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item.addonSummary .am-list-line{
    width: 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0 !important;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item.addonSummary{
    padding-right: 15px;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body .am-list-item.addonSummary .am-list-line{
    width: 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
}
.cartDrawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .viewPortCart .am-list .am-list-body {
    /* margin-bottom: 20px; */
}

@media only screen and (max-width:767px){   
    .cart_item img{
        width: 60px !important;
        height: 60px !important;
        object-fit: cover !important;
    }
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-item .am-list-line .innerItemName p{
        font-size: 13px;
    }
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-item{
        margin-right: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-line{
        padding-left: 10px;
        padding-right: 0;
    }
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-line .am-list-content{
        margin-right: 0;
        width: calc(100% - 20px);
        max-width: calc(100% - 20px);
        -webkit-flex-grow: 0;
                flex-grow: 0;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        -webkit-flex-basis: calc(100% - 20px);
                flex-basis: calc(100% - 20px);
    }
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-item.addonSummary .am-list-line .am-list-content,
    .ant-drawer-content-wrapper .ant-drawer-body .viewPortCart .am-list-item.addonSummary .am-list-line .am-list-extra {
        width: auto;
        max-width: none;
        max-width: initial;
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
    }
   
}

@media only screen and (max-width:480px){   
   .cart-header-text {
    font-size: 24px!important;
    font-weight: 600!important;
  }
  .cartDrawer .checkoutBtn
  {
	  background:#fff;
  }
  .cartDrawer .ant-drawer-content-wrapper{
	  width:90% !important;
  }

}
.cart-header-text {
    padding: 12px;
    font-size: 20px;
    font-weight: 300;
	font-family: Lato !important;
	color: #000000 !important;	
}
.cart-item-text
{
	padding: 12px;
}

.checkoutBtn .withoutLog .poweredByLog {
    margin-bottom: 0px;
}
.checkoutBtn .withoutLog .poweredByLog p{
	width:50%;
	display:-webkit-flex;
	display:flex;
}
.checkoutBtn .withoutLog .poweredByLog p img{
	width:70px;
    top:110px !important;
    height: calc(100% - 110px);
}
/*****for cart view port*******/
.viewPort, .viewPortCart {  
    height: calc(100% - 50px);
	height: -webkit-calc(100% - 50px);
}
.cart-text
{
	left: -9px;
	background-color:green;
	position:relative;
    top: -6px;
    height: 18px;
    line-height: 18px;
    min-width: 9px;
    border-radius: 12px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    -webkit-transform-origin: -10% center;
            transform-origin: -10% center;
    z-index: 10;    
}
/******recommended********/
.recomSection{
    width: 100%;
    max-width: 100%;
    display: flow-root;
    overflow-x: auto;
	padding: 5px;
    padding-bottom:50px ;
}
.itemInfoBox .recomSection .loadingIcon img
{
    width:60px;
	max-width:100%;
}

.recoItem {
    margin-top: 10px;
    display: table-cell;
    font-weight: 400;
    text-align: center;
    word-break: break-word;
    word-wrap: anywhere;
    background-color: #fff;
    font-size: .9em;
    border-top-left-radius: 6px!important;
    border-top-right-radius: 6px!important;
    position: relative;
	padding-right:5px;
	min-width:150px;
}
.recoItem .thumbnailPlaceholder {   
    width: 50%;
}

.recoItem .imageLoaded {   
    width: 70%;
}
.ant-menu-vertical
{
	max-height: calc(100vh - 100px);
    overflow: scroll;
}
.phonesection
{
	display:-webkit-flex;
	display:flex;
	
}
.phonesection select.phoneCode
{
	margin-top: 4px;
    line-height: unset;
    height: 37px;
}
.phonesection .phoneCode
{
	width:30%;
}
.phonewithprefix
{
	width:100%;
}
.phonewithprefix input
{
	width:94% !important;
	margin-left:10px;
}
/* reomved for checkout btnfor unisolon
.unisoloSec .viewPort {
    margin-top: 70px;
}*/
.unisoloSec .menuContainer {
    height: calc(100vh - 120px);
	margin-top: 10px;
}
.flinks
{
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 50px;
    gap: 50px;
}

.infofooter,.pfotter
{
	display: -webkit-flex;
	display: flex;		
	-webkit-flex-wrap:wrap;		
	        flex-wrap:wrap;
}
.infofooter
{
	padding:20px 20px 20px 20px;
	-webkit-justify-content: center;
	        justify-content: center;
	grid-gap:20px;
	gap:20px;
}
.pfotter
{
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;  
  width: 82%;
}
.pfotter img
{
  width:85%;	
}
@media screen and (min-width: 900px) {
.pfotter
{
	width:28%;
}
}
@media screen and (max-width: 480px) {
.flinks
{
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	grid-gap:0px;
	gap:0px;
	
}
.infofooter{
	grid-gap:30px;
	gap:30px;
}
}
.cart_item img {
  width: 15vw !important;
  height: auto !important;
}

.cart_item p {
  margin: 0px;
  font-size: 15px;
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
}

.am-list-brief {
  font-size: 13px !important;
}

.am-list-header {
  font-size: 14px !important;
}

.cart_item .am-list-content {
  margin-right: 10px;
}

.cart_item .am-list-extra {
  overflow: unset !important;
}

.addonHeading {
  text-align: center;
  /* font-weight: 600; */
  font-size: 4vw;
}

.cart_addon p {
  margin: 0px;
  font-size: 3.5vw;
}

.cartHeader:not(:first-child) {
  background-color: #333;
  color: #fff;
  height: 3px;
}

.addonHeader {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 17px;
  text-align: left;
  color: #666;
  border-bottom: 1px dashed #999;
}

.addonSummary .am-list-content,
.addonSummary .am-list-extra {
  font-size: 13px !important;
}

.addonList .am-list-line {
  border-bottom: 1px solid #ddd !important;
}

.am-list-body {
  border: none !important;
}

