* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

*::-webkit-scrollbar {
  display: none;
}

.App,
.am-modal-body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.viewPort,
.viewPortCart {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 45px;
  overflow-y: scroll;
  height: calc(100% - 30px); /*decreased 52 to 30 for spot space*/
  width: 100%;
}

.viewPortCart {
  position: absolute;
  top: 0px;
  height: calc(100% - 50px);
}

.checkoutBtn {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.checkoutBtn .am-button {
  margin: 0 auto;
}

.menuContainer {
  max-height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}

.sideMenuContainer {
  -webkit-overflow-scrolling: touch;
  height: auto;
  overflow-y: scroll;
  float: left;
  flex: 1;
  
}

.sideMenuContainer .am-list-content {
  font-size: 13px !important;
}

.sideMenu {
  width: 100%;
  height: 100%;
}

.mainMenuContainer {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  flex: 4;
}

.mainMenu {
  height: auto;
}

.menuHeader {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 6px;
}

ul,
li {
  list-style: none;
}

/* Item Info */
.itemInfoBox img {
  /*width: 110px !important;*/
  width:100%;
  max-width:512px;
  height: auto !important;
  padding: 6px !important;
  vertical-align: top !important;
}

.itemInfoBox .am-list-content {
  word-break: break-word !important;
  text-overflow: unset !important;
  white-space: unset !important;
}

.itemAddon .am-list-content,
.itemAddon .am-list-brief {
  font-size: 13px !important;
}

.itemAddon .radio {
  padding: 2.5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 5px;
}
.gray {
  color: gray;
}

.gridContainer {
  height: 100%;
  lost-utility: clearfix;
}

@media (min-width: 100px) {
  .gridItem {
    lost-column: 1/2;
  }
}

@media (min-width: 500px) {
  .gridItem {
    lost-column: 1/3;
  }
}

@media (min-width: 700px) {
  .gridItem {
    lost-column: 1/4;
  }
}

@media (min-width: 900px) {
  .gridItem {
    lost-column: 1/5;
  }
}

.gridItem {
  margin-top: 10px;
  display: table-cell;
  font-weight: normal;
  text-align: center;
  word-break: break-word;
  word-wrap: anywhere;
  background-color: #fff;
  font-size: 0.9em;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  position: relative;
}

.gridItemBox {
  margin-top: 5px;
  padding: 9px;
}

.gridItemBox .gridItem:not(:first-child) {
}

.gridItem img {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.orderHeader {
  background-color: #333;
  color: #fff;
  padding: 3px;
  padding-left: 12px;
}

.li-text {
  display: flex;
  flex-direction: column;
  flex: 3;
  vertical-align: middle;
}
.li-head {
  margin: 0;
}
.li-sub {
  flex: 1;
  margin: 0;
}

.max-lines,
.sideMenu .am-list-content {
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
}

.sideMenuContainer .am-list-content {
  text-overflow: clip !important;
}

.navBar .am-navbar-title {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
  text-align: center;
  white-space: normal !important;
  flex: 2.5;
}

.shortTitle .am-navbar-title {
  font-size: 16px;
  font-weight: bold;
  flex: 2.5;
}

.listEntryImage {
  display: block;
  width: 100%;
  height: auto;
}

.addonGroup {
  counter-reset: line-number;
}

.addonIndexNumber {
  counter-increment: line-number;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 2px;
  padding-top: 12px;
  padding-left: 12px;
  font-weight: bold;
  color: black;
}

.addonIndexNumber:before {
  content: counter(line-number) ". ";
}

.am-stepper-input {
  pointer-events: none;
  cursor: default;
}

.specialRequestInput {
  margin-bottom: 9px;
}

.specialRequestInput .am-list-line {
  border-bottom: 1px solid #ddd !important;
}

.specialRequestInput .am-list-item .am-input-label,
.addonList .am-input-label {
  width: 30px !important;
  margin-top: 5px !important;
}

.addonList {
  margin-bottom: 10px !important;
}

@media (min-width: 500px) {
  .listEntryImage {
    display: block;
    width: 110px;
    height: auto;
	min-height:auto;
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.paymentHeader {
  font-weight: bold;
  color: white;
}

.popup-list .am-list-header {
  background-color: #1e90ff;
}

.thumbnailPlaceholder {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: white;
  /*background-image: url("/static/placeholder.jpg");*/
  background-size: contain;
}

.thumbnailPlaceholderLoading {
  position: absolute;
  top: 50%;
  transform: translateXY(-50%, -50%);
  left: 50%;
}

.imageLoaded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
