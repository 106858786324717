.cart_item img {
  width: 15vw !important;
  height: auto !important;
}

.cart_item p {
  margin: 0px;
  font-size: 15px;
  display: block;
  text-overflow: clip !important;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 1.4em;
}

.am-list-brief {
  font-size: 13px !important;
}

.am-list-header {
  font-size: 14px !important;
}

.cart_item .am-list-content {
  margin-right: 10px;
}

.cart_item .am-list-extra {
  overflow: unset !important;
}

.addonHeading {
  text-align: center;
  /* font-weight: 600; */
  font-size: 4vw;
}

.cart_addon p {
  margin: 0px;
  font-size: 3.5vw;
}

.cartHeader:not(:first-child) {
  background-color: #333;
  color: #fff;
  height: 3px;
}

.addonHeader {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 17px;
  text-align: left;
  color: #666;
  border-bottom: 1px dashed #999;
}

.addonSummary .am-list-content,
.addonSummary .am-list-extra {
  font-size: 13px !important;
}

.addonList .am-list-line {
  border-bottom: 1px solid #ddd !important;
}

.am-list-body {
  border: none !important;
}
